import React from "react";

// Utility function to format a Date object to 'YYYY-MM-DD'
function formatLocalYyyyMmDd(dateObj) {
  if (!dateObj) return "";

  const year = dateObj.getFullYear();
  const month = String(dateObj.getMonth() + 1).padStart(2, "0");
  const day = String(dateObj.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}

// Utility function to preserve the time when updating the date
function preserveTime(originalDateObj, newYyyyMmDd) {
  if (!originalDateObj || !newYyyyMmDd) return null;

  const [yyyy, mm, dd] = newYyyyMmDd.split("-");

  const newDate = new Date(originalDateObj.getTime());

  newDate.setFullYear(Number(yyyy));
  newDate.setMonth(Number(mm) - 1);
  newDate.setDate(Number(dd));

  return newDate;
}

function NewDateRangePicker({ change, value = [] }) {
  const [startValue, endValue] = value;

  const startDateObj = startValue ? new Date(startValue) : null;
  const endDateObj = endValue ? new Date(endValue) : null;

  const startDateLocal = startDateObj ? formatLocalYyyyMmDd(startDateObj) : "";
  const endDateLocal = endDateObj ? formatLocalYyyyMmDd(endDateObj) : "";

  const MIN_GAP_DAYS = 1;
  const MAX_GAP_DAYS = 30;

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  const subtractDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
  };

  const handleStartChange = (e) => {
    const newStartString = e.target.value;
    const updatedStart = preserveTime(startDateObj, newStartString);

    let updatedEnd = endDateObj;

    if (updatedStart && endDateObj) {
      const diffTime = endDateObj - updatedStart;
      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > MAX_GAP_DAYS) {
        updatedEnd = addDays(updatedStart, MAX_GAP_DAYS);
      } else if (diffDays < MIN_GAP_DAYS) {
        updatedEnd = addDays(updatedStart, MIN_GAP_DAYS);
      }
    }

    if (updatedStart && !endDateObj) {
      updatedEnd = addDays(updatedStart, MIN_GAP_DAYS);
    }

    change([
      updatedStart ? updatedStart.toISOString() : null,
      updatedEnd ? updatedEnd.toISOString() : null,
    ]);
  };

  const handleEndChange = (e) => {
    const newEndString = e.target.value;
    const updatedEnd = preserveTime(endDateObj, newEndString);

    let updatedStart = startDateObj;

    if (updatedEnd && startDateObj) {
      const diffTime = updatedEnd - startDateObj;
      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays > MAX_GAP_DAYS) {
        updatedStart = subtractDays(updatedEnd, MAX_GAP_DAYS);
      } else if (diffDays < MIN_GAP_DAYS) {
        updatedStart = subtractDays(updatedEnd, MIN_GAP_DAYS);
      }
    }

    if (updatedEnd && !startDateObj) {
      updatedStart = subtractDays(updatedEnd, MIN_GAP_DAYS);
    }

    change([
      updatedStart ? updatedStart.toISOString() : null,
      updatedEnd ? updatedEnd.toISOString() : null,
    ]);
  };

  return (
    <div className="datePicker">
      <input
        type="date"
        value={startDateLocal}
        onChange={handleStartChange}
      />

      <input
        type="date"
        value={endDateLocal}
        onChange={handleEndChange}
      />
    </div>
  );
}

export default NewDateRangePicker;
