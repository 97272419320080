import React, { useState, useRef, useEffect, useReducer, useContext } from 'react'
import Rooms from '../sub-componments/Rooms';
// import { Notification } from '../../components/common/logic/Toggles';
import Platforms from '../../pages/sub-componments/Platforms';
import NewDateRangePicker from '../../pages/sub-componments/NewDateRangePicker.js';
import AsyncSelect from 'react-select/async';
import { Context } from '../../context/store';
import { getDaysBetweenDates } from '../../helpers/date';
import { getRoomById } from '../../helpers/findRange';
import { post } from '../../api/requests/main';
import updateBodyClass from '../../helpers/noScroll';

import { toast } from 'react-toastify';


import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import NewGuest from './newGuest';


const formReducer = (state, action) => {
    switch (action.type) {
        case "update":
            return { ...state, [action.name]: action.value };
        default:
            return state;
    }
};


const filterColors = (inputValue) => {
    const convertedData = inputValue.map(item => {
        const fullName = `${item.name.trim()} ${item.lastname.trim()}`;
        return {
            value: item.id,
            label: fullName
        };
    });
    return convertedData;
};


function NewCheckin({ userId, data, closeInitLogic, popup }) {
    const [disable, setDisable] = useState(false);
    const [mainState, setMainState] = useContext(Context);
    const [cacheOptions] = useState(true);
    const [defaultOptions] = useState(true);
    const [totalDays, setTotalDays] = useState('0');
    const [selectedGuest, setSelectedGuest] = useState(null);
    const [selectedGuestbookingDate, setSelectedGuestbookingDate] = useState(null);
    const [pricePerDay, setPricePerDay] = useState('');
    const [amount, setAmount] = useState('');
    const [defaultPricePerDay, setDefaultPricePerDay] = useState(0);
    const [newGuestPopUp, setNewGuestPopUp] = useState(false);
    const [vcard, setVcard] = useState(false);
    const formRef = useRef("");
    const [value, onChange] = useState([new Date(data.date), new Date(data.date)]);
    // console.log(data)

    const [formState, dispatch] = useReducer(formReducer, {
        checkin: data.date,
        checkout: "",
        amount: 0,
        description: '',
        bed: data.bed,
        platform: undefined,
        pricePerDay: 0,
        defaultPricePerDay: 0
    });

    useEffect(() => {
        if (userId) {
            setDefualtUser(userId)
        }
    }, []);
    useEffect(() => {
        setTotalDays(
            getDaysBetweenDates(value[0], value[1])
        )
    }, [formState, value]);
    useEffect(() => {
        setAmount('')
        setPricePerDay('')
    }, [value]);

    const promiseOptions = async (inputValue) => {
        return new Promise(async (resolve) => {
            if (inputValue.length > 2) {
                try {
                    const response = await post('search-users', { "name": inputValue, property: mainState.selected_Property });
                    if (response.status === 200) {
                        resolve(filterColors(response.data.data));
                    }
                } catch (error) {
                    // handle error
                }
            }
        });
    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        dispatch({ type: "update", name, value });
    };

    const getPrice = async (event) => {
        if (!totalDays) {
            return
        }

        setMainState({ type: 'loader', payload: true })

        const dateRange = [];
        const startDate = new Date(value[0]);
        const endDate = new Date(value[1]);


        startDate.setHours(0, 0, 0, 0);
        endDate.setHours(0, 0, 0, 0);

        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        };

        let currentDate = new Date(startDate);

        if (formatDate(startDate) == formatDate(endDate)) {
            toast('Please select date')
            return
        }

        while (currentDate < endDate) {
            dateRange.push(formatDate(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }

        const prc = await post('get-new-avilablilty', { dates: dateRange, property: mainState.selected_Property })
        if (prc.status === 200) {
            let totalPrice = 0;
            const RoomNumber = getRoomById(prc.data.days, data.room);
            const daysArray = RoomNumber.date
            for (let s of daysArray) {
                if (!s.price) {
                    toast("Selected days not available");
                    break;
                }
                totalPrice += s.price;
            }
            setAmount(totalPrice);
            setPricePerDay((totalPrice / dateRange.length).toFixed(2));
            setDefaultPricePerDay((totalPrice / dateRange.length).toFixed(2));
        }


        setMainState({ type: 'loader', payload: false })
    };



    const setDefualtUser = async (a) => {
        try {
            const response = await post('get-user-by-id', { property: mainState.selected_Property, "id": a });
            if (response.status === 200) {
                setSelectedGuest(response.data.data.user)
                setSelectedGuestbookingDate(response.data.data.bookingDate[response.data.data.bookingDate.length - 1])
            }
        } catch (error) {
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setDisable(true)
        if (
            !selectedGuest?.id ||
            !formRef.current.amount.value ||
            !formRef.current.platform.value ||
            !value[0] ||
            !value[1]
        ) {
            toast.error('Please Fill all the fields!')
            setDisable(false)
            return
        }

        formState.id = selectedGuest.id
        formState.pricePerDay = pricePerDay
        formState.vcard = vcard


        formState.checkin = new Date(value[0]);
        formState.checkin.setDate(formState.checkin.getDate());
        formState.checkin = formState.checkin.toISOString().split('T')[0];
        formState.property = mainState.selected_Property
        formState.checkout = new Date(value[1]);
        formState.checkout = formState.checkout.toISOString().split('T')[0];
        formState.defaultPricePerDay = defaultPricePerDay;


        try {
            const response = await post('add-full-checkin', formState);
            if (response.status === 201) {
                toast('Booking Saved!')
                popup(false)
                setDisable(false)
                updateBodyClass("show", setMainState)
                if (mainState.reload) {
                    setMainState({ type: "reload", payload: false });
                } else {
                    setMainState({ type: "reload", payload: true });
                }
            }
        } catch (error) {
            toast.error('Something went wrong!')
            updateBodyClass("show", setMainState)
            setDisable(false)
        }
    }

    const getNewUser = async (a) => {
        setSelectedGuest(undefined)
        try {
            const response = await post('get-user-by-id', { property: mainState.selected_Property, "id": a.value });
            if (response.status === 200) {
                setSelectedGuest(response.data.data.user)
                setSelectedGuestbookingDate(response.data.data.bookingDate[response.data.data.bookingDate.length - 1])
            }
        } catch (error) {
        }
    };

    const newGstPop = async (a) => { newGuestPopUp ? setNewGuestPopUp(false) : setNewGuestPopUp(true); };

    const AmountUpdate = (event) => {

        // update amount 
        handleChange(event)
        setAmount(event.target.value)

        // update bed perday 
        let ppd = Number(event.target.value / totalDays).toFixed(4)
        setPricePerDay(ppd)
        handleChange({ target: { name: 'pricePerDay', value: Number(ppd) } })

    }

    const PriceDay = (event) => {
        // update Price Per Day
        handleChange(event)
        setPricePerDay(event.target.value)


        // update bed perday 
        let amot = Number(event.target.value * totalDays).toFixed(4)
        setAmount(amot)
        handleChange({ target: { name: 'amount', value: Number(amot) } })

    }

    return (
        <>
            {!userId && (

                <header>
                    <h4>Add New Booking
                        {selectedGuest ? <></> :
                            <small className='a solid' onClick={newGstPop} > + add new guest</small>
                        }

                    </h4>
                    <span onClick={closeInitLogic}> <i className="fa-solid fa-xmark"></i> </span>
                </header>
            )}
            <section>
                <form ref={formRef}>

                    {!userId && (

                        <label>Name
                            <AsyncSelect
                                onChange={getNewUser}
                                cacheOptions={cacheOptions}
                                defaultOptions={defaultOptions}
                                loadOptions={promiseOptions}
                            />
                        </label>
                    )}
                    {selectedGuest === undefined ? <div className='card dark flex'>Loading ...</div> : ""}
                    {selectedGuest ?
                        <div className='card dark flex full-wd'>
                            Name: {selectedGuest.name} {selectedGuest.lastname} <br />
                            Phone: {selectedGuest.phone}
                            {selectedGuestbookingDate ?
                                <p> Last Rate: <b>{Number(selectedGuestbookingDate.amount).toFixed(2)}</b> ({selectedGuestbookingDate.bookingDate}) </p>
                                : <></>}
                        </div>
                        : ""
                    }
                    <div className='flex genPrice'>
                        <label>
                            Check-in : (<b>{totalDays} </b>Days)
                            <NewDateRangePicker change={onChange} value={value} />
                            {/* <DateRangePicker onChange={onChange} value={value} className="form-control" /> */}
                        </label>
                        <p className={totalDays ? 'btn' : "btn disabled"} onClick={getPrice}> Get Price </p>
                    </div>
                    <div className='flex'>
                        <label>
                            <span>Bed Number: </span>
                            <select className='form-select' name="bed" onChange={handleChange} defaultValue={data.bed}>
                                <Rooms />
                            </select>
                        </label>
                        <label>
                            <span>Platform: </span>
                            <select className='form-select' name='platform' onChange={handleChange} required="required" >
                                <option> </option>
                                <Platforms />
                            </select>
                        </label>
                    </div>
                    <div className='flex'>
                        <label>Total Reservation Price
                            <input type="number" onChange={AmountUpdate} onWheel={(e) => e.target.blur()} readOnly={amount ? false : true} value={amount} pattern="[0-9]*" name="amount" required="required" className="form-control" />
                        </label>
                        <label>Price Per-day
                            <input type="number" onChange={PriceDay} onWheel={(e) => e.target.blur()} readOnly={amount ? false : true} value={pricePerDay} pattern="[0-9]*" className="form-control" />
                        </label>
                    </div>
                    <br />
                    <textarea name='description' className='textArea' onChange={handleChange} placeholder=' Write here any additional notes for the guest'></textarea>
                    {/* {mainState.user.is_staff == 3 ?
                        <></> :
                        <label className='flex v-card'>
                            <span>V-Card Payment</span>
                            <input type="checkbox" name="vcard" onChange={(a) => { setVcard(a.target.checked) }} value='true' required="required" className="form-check-input" />
                        </label>
                    } */}
                    <input type='hidden' value={pricePerDay} name='pricePerDay' />
                    <input type='hidden' value={defaultPricePerDay} name='defaultPricePerDay' />
                </form>
            </section>
            <footer className='actions'>
                <button type="submit" onClick={handleSubmit} disabled={disable} className="btn btn-primary main">New Booking</button>
            </footer>

            {newGuestPopUp && !selectedGuest ? <div className='popup newgst'>
                <div className='child minH'>
                    <header>
                        <h4> Add New Guest </h4>
                        <span onClick={newGstPop}><i className="fa-solid fa-xmark"></i> </span>
                    </header>
                    <div>
                        <NewGuest setNewGuest={setSelectedGuest} />
                    </div>
                </div>
            </div> : <></>}
        </>
    )
}

export default NewCheckin