export function getDaysBetweenDates(date1, date2) {
    if (!date1 || !date2) {
      return 0;
    }
  
    const oneDayInMs = 24 * 60 * 60 * 1000;
  
    const firstDate = new Date(date1);
    const secondDate = new Date(date2);
  
    if (isNaN(firstDate) || isNaN(secondDate)) {
      throw new Error('Invalid date format');
    }
  
    const firstUTC = Date.UTC(firstDate.getFullYear(), firstDate.getMonth(), firstDate.getDate());
    const secondUTC = Date.UTC(secondDate.getFullYear(), secondDate.getMonth(), secondDate.getDate());
  
    const diffInMs = secondUTC - firstUTC;
    const diffInDays = Math.round(diffInMs / oneDayInMs);
  
    return diffInDays;
  }
  